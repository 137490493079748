<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">科室标化工作量</h2>
        <el-table
          :data="DeplisttableData"
          border
          center
        >
          <el-table-column
            type="index"
            label="序号"
            width="55"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="科室名称"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="updateDate"
            label="更新时间"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
          >
            <template
              style="display: flex"
              slot-scope="scope"
            >
              <el-button
                size="mini"
                type="primary"
                @click="editJixiao(scope.row)"
              ><i
                  class="el-icon-s-operation"
                  style="font-size: 15px"
                ></i></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </el-main>
    </el-container>
    <!-- 点击操作科室标化工作总量 -->
    <el-dialog
      title="科室标化工作量"
      :visible.sync="totalStandardizeddialogVisible"
      width="80%"
      top="10vh"
      :before-close="totalStandardizeddialogVisibleClose"
    >
      <totalstand
        ref="totals"
        :depart='keshiId'
        :hospital='hospital'
        :depName='depName'
      ></totalstand>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";
import totalstand from "./TotalstandNoHo.vue";
export default {
  data() {
    return {
      hospital: "医院管理员",
      // 科室标化工作总量弹出框
      totalStandardizeddialogVisible: false,
      // 表格数据
      DeplisttableData: [],
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      // 总页数
      total: 1,
      // 每页页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 科室id
      departmentId: window.sessionStorage.getItem("departmentId"),
      // 排序参数
      orderBy: "",
      // 科室列表的id
      keshiId: "",
      // 导出xsl的科室的名字
      exporName: "",
      depName:''
    };
  },
  created() {
    this.getDepartmentNotPage();
  },
  components: {
    totalstand,
  },
  methods: {
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getDepartmentNotPage();
    },
    //   获取医院所属科室列表
    async getDepartmentNotPage() {
      let data = {
        hospitalId: this.hospitalId,
        pageNum: this.pageNum, //页数
        pageSize: this.pageSize, //每页显示数量
        isWorkload:"1",//标化工作量 0 不需要  1 需要
      };
      let { data: res } = await this.$axios.getDepartmentList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 500) {
        this.$message({
          message: "获取医院所属科室列表失败",
          type: "error",
        });
      } else if (res.code == 200) {
        this.DeplisttableData = res.rows;
        this.total = res.total;
      }
    },
    editJixiao(row) {
      this.exporName = row.name;
      this.keshiId = row.id;
      this.depName = row.name;
      this.totalStandardizeddialogVisible = true;
      this.$nextTick(function () {
        // 调用$nextTick函数
        this.$refs.totals.getDepartmentYearList();
      });

      // this.getDepartmentYearList();
    },
    totalStandardizeddialogVisibleClose() {
      this.totalStandardizeddialogVisible = false;
    },
    // 获取科室标化工作总量列表 分页
    async getDepartmentYearList() {
      let data = {
        pageNum: this.totalStandardizedpageNum,
        pageSize: this.totalStandardizedpageSize,
        departmentId: this.keshiId, //科室id
      };
      let { data: res } = await this.$axios.getDepartmentYearList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 500) {
        this.$message({
          message: "获取科室标化工作量列表失败",
          type: "error",
        });
      } else if (res.code == 200) {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.FormType .el-select {
  width: 130px;
}

.downBut {
  width: 44px;
  height: 28px;
  position: relative;
  top: 4px;
  .el-icon-download {
    font-size: 16px;
    position: relative;
    top: -2px;
    left: -1px;
  }
}

.hosform {
  display: flex;
  .hosdanwei {
    margin-left: 10px;
  }
}

.activeStatus {
  background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.noStatus {
  background: #ccc;
}
.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 30px;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}
</style>
